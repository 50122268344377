// rails
// images
// style

require('@rails/ujs').start();

const images = require.context('../../../images/carbon_hospitals', true);
const imagePath = (name) => images(name, true); // eslint-disable-line no-unused-vars

// javascript
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
